import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import Layout from "../components/layout"
import Card from "../components/Card/Card.js"
import GridSpan from "../components/GridSpan/GridSpan.js"
import BackgroundImage from 'gatsby-background-image'

import WaitlistForm from "../components/WaitlistForm/WaitlistForm";

import { GatsbySeo } from 'gatsby-plugin-next-seo';

export default function JoinWaitlist({className}) {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "Waitlist/waitlist.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageData = data.desktop.childImageSharp.fluid

  return (
      <Layout>
        <GatsbySeo
          title="Join the Waitlist"
          description="Join the waitlist for ampmusic.co's upcoming product release."
        />
        <GridSpan span={[12,6,6,6]}>
          <BackgroundImage style={{borderRadius: "16px", overflow: "hidden", width: "100%"}}
            Tag="section"
            fluid={imageData}
            backgroundColor={`#040e18`}
          >
          </BackgroundImage>
        </GridSpan>

        <GridSpan span={[12,6,6,6]}>
          <Card>
            <GridSpan span={[2,2,2,2]} justifyItems="start">
              <h2 style={{color: "var(--neutral-01)", 'text-align': "left"}}> join the waitlist </h2>
              <h5 style={{color: "var(--neutral-03)", 'text-align': "left"}}> Enter your email to claim your dashboard </h5>
              <WaitlistForm />
            </GridSpan>
          </Card>
        </GridSpan>
      </Layout>  )
}