import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { FaArrowRight, FaCheck, FaAt, FaUser, FaTimes } from 'react-icons/fa';
import "./WaitlistForm.scss";
import Button from '../Button/Button'
/* import axios from 'axios'
 */
const WaitlistForm = () => (
  <Formik
    initialValues={{ firstName: "", email: "" }}
    onSubmit={(values, { setSubmitting }) => {
      setTimeout(() => {
        console.log("Logging in", values);
        setSubmitting(false);
      }, 500);
      const form = document.querySelector('form');
      const formURL = 'https://kkkoiwmlxh.execute-api.us-west-2.amazonaws.com/Prod/submitForm';
      var xhr = new XMLHttpRequest();
      xhr.open(form.method, formURL, true);
      xhr.send(JSON.stringify(values));
      document.getElementById("form").style.display = "none";
      document.getElementById("success").style.display = "grid";
    }}

    validationSchema={Yup.object().shape({
      email: Yup.string()
        .email()
        .required("Required"),
      firstName: Yup.string()
        .required("Required")
    })}
  >
    {props => {
      const {
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit
      } = props;
      return (
        <div 
          style={{
            width: "100%",
          }}>
        <form id="form" method="POST" onSubmit={handleSubmit}
          style={{
            display: "grid",
            justifyItems: "stretch",
            gridTemplateColumns: "1fr",
            alignItems: "start",
            gap: 8,
            boxSizing: "border-box"
          }}
        >
              <div className="floating-label-group">
                <input
                  name="firstName"
                  type="text"
                  id="firstName"
                  autocomplete="off"
                  placeholder="John"
                  autofocus 
                  required
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={"form-control" && errors.firstName && touched.firstName && "error"}
                />
                {errors.firstName && touched.firstName && (
                  <div className="input-feedback"><FaTimes/><span>{errors.firstName}</span></div>
                )}
                <label className="floating-label" htmlFor="firstName"><FaUser/>first name</label>
              </div>

              <div className="floating-label-group">
                <input
                  name="email"
                  type="text"
                  id="email"
                  autocomplete="off" 
                  placeholder="john.lennon@beatles.uk"
                  autofocus 
                  required
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={"form-control" && errors.email && touched.email && "error"}
                />
                {errors.email && touched.email && (
                  <div className="input-feedback"><FaTimes/><span>{errors.email}</span></div>
                  )}
                <label className="floating-label" htmlFor="email"><FaAt/>email</label>
              </div>
            <Button
              icon={<FaArrowRight/>}
              type="submit"
              disabled={isSubmitting}
              style={{margin: "0",justifySelf: "start", marginTop:16}}
            >
              join waitlist
            </Button>
        </form>
        
        <div id="success" style={{display: "none"}}>

          <div 
              className="checkbox"
              style={{
                color: "#007A4B",
                display: "inline-block",
                // margin: "0px 16px",
                background: "rgba(0, 122, 75, 0.05)",
                borderRadius: "8px",
                fontSize: 16,
                lineHeight: 16,
                width:"32px",
                height:"32px",
                position: "relative",}}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                display: "flex",
              }}  
            >
              <FaCheck/> 
            </div>
          </div>
          <p
            style={{
              marginTop: 4
            }}
          >
           Thank you 
          <span style={{fontWeight: "bold", textTransform: "capitalize"}}> {values.firstName}</span>
           , we’ll email you at 
          <span style={{fontWeight: "bold", textTransform: "lowercase"}}> {values.email} </span>
          when your dashboard is available!
          </p>
        </div>
      </div>
      );
    }}
  </Formik>
);

export default WaitlistForm;